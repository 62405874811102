import React from 'react'
import Layout from '../components/Layout'
import Form from '../components/Form/reset'
import View from '../components/View'
import { navigate, globalHistory } from '@reach/router'
import { handleReset, isLoggedIn } from '../utils/auth'
import { parseQuery } from '../utils/query'

class Reset extends React.Component {
  state = {
    username: ``,
    resetCode: ``,
    password: ``,
  }

  componentDidMount() {
    var query = this.state.query;
    if(globalHistory.location.search){
      query = parseQuery(globalHistory.location.search)
      if(query.email){
        this.setState({
          username: query.email
        })
      }
    }
  }
  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    var updateMessage = function(text){
      var nevent = {
        target: {
          name: 'errorMessage',
          value: text
        }
      }
      this.handleUpdate(nevent)
    }.bind(this)

    var redirect = function(){
      navigate('/')
    }

    handleReset(this.state, function(err, user){
      if(err){
        updateMessage(err.message)
      }else{
        redirect()
      }

    })
  }

  render() {
    isLoggedIn()
    .then(user =>{
      if(user){
        navigate('/')
      }
    })
    return (
      <Layout>
      <View title='Reset Password'>
        <Form
          handleUpdate={e => this.handleUpdate(e)}
          handleSubmit={e => this.handleSubmit(e)}
          username={this.state.username}
        />
        <div>{this.state.errorMessage}</div>
      </View>
      </Layout>
    )
  }
}

export default Reset
