import React from 'react'
import styles from './form.module.css'
import { navigate } from '@reach/router'

export default(({ handleUpdate, handleSubmit, username, isDisabled, errors, history }) => (
  <form
    className={styles.form}
    method="post"
    onSubmit={event => {
      handleSubmit(event)
      navigate(`/reset`)
    }}
  >
    <p className={styles[`form__instructions`]} />
    <label className={styles[`form__label`]}>
      Email
      <input
        className={styles[`form__input`]}
        type="email"
        name="username"
        value={username}
        onChange={handleUpdate}
      />
    </label>
    <label className={styles[`form__label`]}>
      Reset Code
      <input
        className={styles[`form__input`]}
        type="text"
        name="resetCode"
        onChange={handleUpdate}
      />
    </label>
    <label className={styles[`form__label`]}>
      New Password
      <input
        className={styles[`form__input`]}
        type="password"
        name="password"
        onChange={handleUpdate}
      />
    </label>
    <input
      className={styles[`form__button`]}
      type="submit"
      value="Reset Password"
      disabled={isDisabled}
    />
  </form>
))
